import GoogleTagManager from '@/components/GoogleTagManager';
import MetaPixel from '@/components/MetaPixel';
import { setSourceAttribution } from '@/lib/analytics';
import { localStorage } from '@/lib/localStorage';
import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Analytics as VercelAnalytics } from '@vercel/analytics/react';
import { AnimatePresence } from 'framer-motion';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
import { AppProvider } from '../providers/AppProvider';
import '../styles/globals.scss';
import { theme } from '../theme';

if (typeof window !== 'undefined') {
    const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
    if (!posthogKey) {
        throw new Error('PostHog key is not defined');
    }

    posthog.init(posthogKey, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://eu.i.posthog.com',
        person_profiles: 'identified_only',
        loaded: (posthog) => {
            if (process.env.NODE_ENV === 'development') posthog.debug();
        },
    });
}

export default function App({ Component, pageProps }: AppProps) {
    const env = process.env.NEXT_PUBLIC_APP_ENV;
    const router = useRouter();

    useEffect(() => {
        if (env === 'development') {
            console.log('💻 Development');
        } else if (env === 'production') {
            console.log('🚗 Clidriving 🚗');
        }
    }, [router.events, env]);

    useEffect(() => {
        const q = router.query;

        const now = new Date();
        if (router.isReady) {
            localStorage.set('first-pageview-time-ms', now.getTime());
            if (Object.keys(q).length !== 0) {
                localStorage.set('routerQuery', q);
            }
            setSourceAttribution(q);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady]);

    useEffect(() => {
        localStorage.set('first-pageview-time-ms', new Date().getTime());
        const url = new URL(window.location.href);
        const referrer = document.referrer;
        function getMedium(referrer: string) {
            const SOURCE_MAP = ['google', 'bing', 'duckduckgo', 'yahoo', 'ask', 'aol', 'yandex'];
            return SOURCE_MAP.some((item) => referrer.includes(item)) ? 'organic' : 'referral';
        }
        function getSourceAttribution(url: URL) {
            return {
                Source: url.searchParams.get('utm_source') ?? (referrer || 'direct'),
                Medium: url.searchParams.get('utm_medium') ?? getMedium(referrer),
                Campaign: url.searchParams.get('utm_campaign') ?? '',
                ClickId: url.searchParams.get('click_id') ?? '',
            };
        }

        if (!localStorage.get('first-source-attribution')) {
            localStorage.set('first-source-attribution', getSourceAttribution(url));
        } else {
            localStorage.set('last-source-attribution', getSourceAttribution(url));
        }

        const handleRouteChange = () => posthog.capture('$pageview');
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    return (
        <>
            <Script
                id="Cookiebot"
                src="https://consent.cookiebot.com/uc.js"
                data-cbid="6ef48cf1-f9c2-4bf7-8ac1-bf582f3060be"
                data-blockingmode="auto"
                type="text/javascript"
            />
            <GoogleTagManager />
            <MetaPixel />
            <PostHogProvider client={posthog}>
                <ChakraProvider theme={theme}>
                    <AnimatePresence mode="wait" initial={false}>
                        <AppProvider>
                            <Component {...pageProps} />
                        </AppProvider>
                    </AnimatePresence>
                    <VercelAnalytics />
                </ChakraProvider>
            </PostHogProvider>
        </>
    );
}
